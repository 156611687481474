export const USER_TYPES = {
  CREATOR: "Creator",
  USER: "User",
};

export const POST_TYPES = {
  NORMAL: "Normal",
  SCHEDULED: "Scheduled",
};

export const POST_STATUS = {
  ACTIVE: "Active",
  INACTIVE: "InActive",
};
export const FILE_LIMIT = {
  RESOLUTION_X: 6000,
  RESOLUTION_Y: 6000,
  MIN_RESOLUTION_X: 200,
  MIN_RESOLUTION_Y: 200,
  SIZE: 3 * 1024 * 1024 * 1024,
};

export const FILE_PREFIX_CODE = "255";

export const AWS_URL = process.env.REACT_APP_AWS_S3_URL;
export const PLACEHOLDER = AWS_URL + "logo/user_image.jpeg";
export const PLACEHOLDER_BANNER = AWS_URL + "logo/placeholderBanner.png";
export const API_BASEURL = process.env.REACT_APP_API_BASEURL;
export const API_CCBILL = "https://api.ccbill.com";
export const SOCKET_BASEURL = process.env.REACT_APP_SOCKET_URL;
export const API_SLUG = {
  HOME: "HOME",
  PROFILE: "PROFILE",
};

export const POST_SECTION_SLUG = {
  COMMENT: "COMMENT",
  REACTION: "REACTION",
};

export const POST_SECTION_VALUES = {
  COMMENT: {
    single: "comment",
    multiple: "comments",
  },
  REACTION: {
    single: "reaction",
    multiple: "reactions",
  },
};

export const REDUX_LOCAL_STORE_KEY = process.env.REACT_APP_NAME_TAG;
export const SWIPEABLE = true;
export const MENU_SLUGS = Object.freeze({
  BOOKMARKS: "BOOKMARKS",
  COLLECTIONS: "COLLECTIONS",
});

export const ISSUE_TYPES = [];
ISSUE_TYPES[0] = "Account";
ISSUE_TYPES[1] = "Subscription";
ISSUE_TYPES[2] = "Payment";
ISSUE_TYPES[3] = "Other";

export const DATE_FORMAT = {
  LONG: "dd MMMM yyyy, hh:mma",
  SHORT: "dd MMMM yyyy",
  QUERY: "yyyy-MM-dd",
};

export const REPORT_TYPES = [];
REPORT_TYPES[0] = "report.types.sexual";
REPORT_TYPES[1] = "report.types.hate";
REPORT_TYPES[2] = "report.types.scam";
REPORT_TYPES[3] = "report.types.violence";
REPORT_TYPES[4] = "report.types.illegal_products";
REPORT_TYPES[5] = "report.types.harrassment";
REPORT_TYPES[6] = "report.types.copyright";
REPORT_TYPES[7] = "report.types.suicide";
REPORT_TYPES[8] = "report.types.spam";
REPORT_TYPES[9] = "report.types.other";

export const SUBSCRIPTION_STATUS = {
  ACTIVE: "Active",
  INACTIVE: "InActive",
};

export const MASS_MESSAGE_STATUSES = {
  ACTIVE: "Active",
  INACTIVE: "InActive",
  UNSENT: "Unsent",
};

export const LANGUAGES = [
  { id: "en", text: "language.en" },
  { id: "es", text: "language.es" },
  { id: "it", text: "language.it" },
  { id: "fr", text: "language.fr" },
  { id: "de", text: "language.de" },
  { id: "pt", text: "language.pt" },
  { id: "hi", text: "language.hi" },
  { id: "ar", text: "language.ar" },
  { id: "bg", text: "language.bg" },
  { id: "cz", text: "language.cz" },
  { id: "id", text: "language.id" },
  { id: "ja", text: "language.ja" },
  { id: "ko", text: "language.ko" },
  { id: "ro", text: "language.ro" },
  { id: "ru", text: "language.ru" },
  { id: "sq", text: "language.sq" },
  { id: "uk", text: "language.uk" },
  { id: "zhcn", text: "language.zhcn" },
  { id: "zhtw", text: "language.zhtw" },
];

export const I18NEXTLNG = {
  TAG: "i18nextLng",
};

export const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

export const image_headers = {
  Accept: "application/json",
  "Content-Type": "multipart/form-data",
  "Access-Control-Allow-Origin": "*",
};
